<template>
  <v-dialog :value="value" max-width="800px" persistent scrollable>
    <v-card class="pb-0">
      <v-toolbar class="px-3" flat :style="{'background-color': $vuetify.theme.dark ? '#272727' : '#8898aa'}">
        <div class="title white--text">
          <!-- {{$t('addCreditCard')}} -->
          {{ 'Código de verificación' }}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelLogin()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text :style="{'background-color': $vuetify.theme.dark ? '#1e1e1e' : '#ffffff'}">
        <v-form ref="form">
          <v-row>
            <v-col cols="12"
                   class="d-flex justify-center">
              <div class="d-flex flex-column align-center">
                <!-- <v-icon
                        class="mb-1"
                        size="80px">
                  mdi-numeric
                </v-icon> -->
                <span class="text-h5 font-weight-medium">
                <!-- {{ $t('ticketAccesCode') }} -->
                {{ 'Hemos enviado un código de verificación a tu celular y tu correo electrónico' }}
              </span>
              </div>
            </v-col>
            <v-col cols="12" class="pt-12">
              <v-text-field
                v-model="accessCode"
                :label="$t('loginAccessCode')"
                :rules="[rules.required, rules.onlyNumbers, rules.minLength(accessCode, 4)]"
                outlined
                prepend-icon="mdi-numeric"
              ></v-text-field>
              <!-- <v-text-field
                v-model="cardNumber"
                :rules="[rules.required]"
                :label="$t('cardNumber')"
                v-mask="'#### #### #### #### ####'"
                dense
                outlined
              ></v-text-field> -->
            </v-col>

            <v-col
              cols="12" class="py-0 text-center"
            >
              <v-checkbox
                v-model="acceptedTerms"
                dense outlined
              >
                <template v-slot:label>
                  <div>
                    {{ $t('iAgreeWithThe') }}
                    <a
                      target="_blank"
                      href="https://keybe.ai/es/legales/#acuerdo-de-privacidad"
                      @click.stop
                    >
                    {{ $t('privacyAgreement') }}
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red"
               class="mr-1"
               outlined
               @click="cancelLogin()">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="submitDisabled"
          @click="submit()"
          color="kbGreen"
          class="white--text"
          :loading="loadingLogin">
          {{ $t('goToPayment') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapState } from 'vuex'

import rules from '@/utils/rules'
import notifications from '@/plugins/notifications'
export default {
  components: { },
  directives: { mask },
  computed: {
    ...mapState({
    }),
    submitDisabled () {
      return !(this.accessCode)
    },
    rules () {
      return rules(this.$t.bind(this))
    }
  },
  data () {
    return {
      accessCode: '',
      acceptedTerms: false,
      loadingLogin: false,
      validatedLogin: {}
    }
  },
  async created () {
    const { phone = {}, email = '', name = '' } = this.mainAttendee
    // this.email = email
    // console.log('phone')
    // console.log(phone)
    // Validar si existe
    let existsByPhone = true
    let { isNewUser, userExist } = await this.checkUserExist({ emailOrPhone: phone.number, countryCode: phone.countryNumber }) || {}
    // console.log('isNewUser, userExist')
    // console.log(isNewUser, userExist)
    if (isNewUser) {
      existsByPhone = false
      ;({ isNewUser, userExist } = await this.checkUserExist({ emailOrPhone: email }) || {})
      // console.log('isNewUser, userExist')
      // console.log(isNewUser, userExist)
    }
    // console.log('isNewUser')
    // console.log(isNewUser)
    if (isNewUser) {
      this.validatedLogin = {
        phone: phone, email: email, name
      }
    } else {
      if (existsByPhone) {
        this.validatedLogin.phone = phone
      } else {
        this.validatedLogin.email = email
      }
    }
    await this.sendAccessCode(this.validatedLogin, isNewUser, userExist)
  },
  methods: {
    clearInputs () {
      this.$refs.form.reset()
    },
    async submit () {
      try {
        if (!this.$refs.form.validate()) return
        if (!this.acceptedTerms) return notifications.error(this.$t('youMustAcceptThePrivacyAgreement'))

        const accessCode = this.accessCode.replaceAll(' ', '')

        try {
          // console.log('additional_info_needed')
          // console.log(response[0].additional_info_needed)
          this.loadingLogin = true
          const { phone = {}, email = '', name = '' } = this.validatedLogin

          console.log('this.validatedLogin')
          console.log(this.validatedLogin)

          const user = await this.$store.dispatch('authKeybe/validateSmsCodeToLogin', {
            email,
            phone: phone.number,
            countryCode: phone.countryNumber,
            name,
            code: accessCode
            // password: this.password
          })
          console.log('user')
          console.log(user)
          this.loadingLogin = false
          // notifications.success(this.$t('general.creditCardSuccessfullyAdded'))
          notifications.success('El código ha sido validado')
          this.$emit('successLogin')
          this.toggleModal()
        } catch (error) {
          console.error(error)
          notifications.error('No se pudo validar el código de acceso')
          this.loadingLogin = false
        }
      } catch (error) {
        console.error(error)
        notifications.error('No se pudo validar el código de acceso')
        this.loadingLogin = false
      }
    },
    async checkUserExist ({ emailOrPhone, countryCode }) {
      if (!emailOrPhone) return console.log('No hay email o phone')
      try {
        // this.checkingUserExist = true
        const userExist = await this.$store.dispatch(
          'authKeybe/checkUserExist',
          {
            emailOrPhone,
            countryCode
            // validationMethod: this.validationMethod
          }
        )
        // this.checkingUserExist = false
        // console.log('userExist')
        // console.log(userExist)
        const isNewUser = userExist && !userExist.exist
        // if (userExist.hasSetPassword !== undefined) this.$store.commit('SET_HAS_SET_PASSWORD', !!userExist.hasSetPassword)
        // if (userExist.hasSetName !== undefined) this.$store.commit('SET_HAS_SET_NAME', !!userExist.hasSetName)
        return { isNewUser, userExist }
      } catch (error) {
        console.error(error)
        notifications.error(error.message)
        // this.checkingUserExist = false
      }
    },
    async sendAccessCode ({ phone = {}, email }, isNewUser) {
      try {
        // Enviar codigo de acceso
        if (isNewUser) {
          // send request to send the sms code
          const sentCode = await this.$store.dispatch('authKeybe/sendSmsCodeToRegister', { email, phone: phone.number, countryCode: phone.countryNumber })
          if (sentCode) {
            return notifications.success('Código de acceso enviado')
          }
        } else {
          // if (userExist.hasSetPhone) {
          // send request to send the sms code
          const sentCode = await this.$store.dispatch('authKeybe/sendSmsCodeToLogin', { email, phone: phone.number, countryCode: phone.countryNumber })
          if (sentCode) {
            return notifications.success('Código de acceso enviado')
          }
          // }
          // else {
          //   const sentCode = await this.$store.dispatch('authKeybe/sendSmsCodeToLogin')
          //   if (sentCode) {
          //     return notifications.success('Código de acceso enviado')
          //   }
          // }
          // else {
          //   // must set phone and country
          //   notifications.info(this.$t('existingAccountWithoutPhoneConfigured'))
          //   this.$emit('setSection', 'Login')
          // }
        }
      } catch (error) {
        console.error(error)
        // notifications.error(error.message)
        // this.checkingUserExist = false
      }
    },
    cancelLogin () {
      this.clearInputs()
      this.$emit('cancelLogin')
      this.toggleModal()
    },
    toggleModal () {
      this.$emit('input', false)
      this.clearInputs()
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    mainAttendee: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
