<template>
    <div class="ma-0 pa-0">
      <vue-tel-input
        :preferred-countries="['co', 'us', 'br']"
        :valid-characters-only="true"
        :enabledCountryCode="true"
        required
        :value="value.phone"
        :defaultCountry="value.code"
        :placeholder="$t('enterAPhoneNumber')"
        @close="$emit('close')"
        @country-changed="onCountryChange"
        @input="onInput"
        @open="$emit('open')"
        class="pa-2"
        :class="$vuetify.theme.isDark ? 'dark' : null"
      />
      <span v-if="phone.valid && value.phone" style="color: green; font-size: 10px">{{ $t('validPhone') }}</span>
      <span v-else style="color: red; font-size: 10px">{{ $t('invalidPhone') }}</span>
    </div>
</template>

<script>
export default {
  data: () => ({
    phone: {
      number: '',
      valid: true,
      countryName: undefined,
      cuntryNumber: undefined,
      countryCode: undefined
    }
  }),
  methods: {
    onCountryChange (country) {
      this.phone.countryName = country.name.toLowerCase()
      this.phone.countryCode = country.iso2.toLowerCase()
      this.phone.countryNumber = country.dialCode
      this.emitInput()
    },
    onInput (formattedNumber, { valid, country }) {
      setTimeout(() => {
        if (formattedNumber[0] === '+') {
          formattedNumber = formattedNumber.trim()
          const tempPhone = formattedNumber.split(/\s+/)
          tempPhone.shift()
          formattedNumber = tempPhone.join('')
        }

        if (formattedNumber) formattedNumber = formattedNumber.replace(/(\()|-|\)|\s/g, '')

        this.phone.number = formattedNumber
        this.phone.valid = valid
        this.emitInput()
      }, 100)
    },
    emitInput () {
      const data = {
        country: this.phone.countryName,
        code: this.phone.countryCode,
        number: this.phone.countryNumber,
        phone: this.phone.number || this.value.phone,
        valid: this.phone.valid
      }
      // console.log('emiting input')
      // console.log(data)
      this.$emit('input', data)
    }
  },
  props: {
    value: {
      default: () => {
        return {
          phone: ''
        }
      },
      type: Object
    }
  }
}
</script>

<style scoped>
  ::v-deep .vti__dropdown-list.above {
    top: 33px!important;
    bottom: unset!important;
  }
  ::v-deep ul.vti__dropdown-list {
    border: none!important;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.4);
    padding-top: 10px!important;
    width: 280px!important;
  }
  ::v-deep ul.vti__dropdown-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;
    background-color: #F5F5F5!important;
  }

  ::v-deep ul.vti__dropdown-list::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5!important;
  }

  ::v-deep ul.vti__dropdown-list::-webkit-scrollbar-thumb {
    background-color: #737373!important;
  }
  ::v-deep ul.vti__dropdown-list li.vti__dropdown-item {
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
  ::v-deep input {
    color: #172B4D;
  }
  ::v-deep .dark input {
    color: #FFFFFF;
  }
  ::v-deep .dark .vti__country-code {
    color: #FFFFFF;
  }
  ::v-deep .dark .vti__dropdown:hover .vti__country-code {
    color: black;
  }
  ::v-deep .dark .vti__dropdown-item {
    color: black;
  }
</style>
