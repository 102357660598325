<template>
  <v-card class="kbSemiBlack mx-5"
          style="position: relative; border-radius: 10px!important;">
    <v-card-text>
      <v-row class="mx-0">
        <v-col cols="12" class="py-0 d-flex align-center justify-space-between">
          <span style="font-size: 18px; font-weight: 500">{{ attendeeIndex + 1 }}.</span>
          <v-btn icon x-small @click="deleteAttendee()">
            <v-icon>mdi-delete-circle</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            name="name"
            :label="$t('fullName')"
            :value="attendee.name"
            @input="onAttendeeChanges({ key: 'name', value: $event })"
            :rules="[rules.required]"
            prepend-icon="mdi-account-child-outline" />
        </v-col>

        <!-- <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            :label="$t('docNumber')"
            :value="attendee.document"
            @input="onAttendeeChanges({ key: 'document', value: $event })"
            :rules="[rules.required, rules.onlyNumbers]"
            prepend-icon="mdi-card-text-outline" />
        </v-col> -->

        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            label="Email"
            :value="attendee.email"
            @input="onAttendeeChanges({ key: 'email', value: $event })"
            :rules="[rules.required, rules.email]"
            prepend-icon="mdi-email-outline" />
        </v-col>

        <v-col cols="12" md="6" class="pt-0">
          <!-- <v-text-field
            :label="$t('phone')"
            :rules="[rules.required, rules.onlyNumbers]"
            prepend-icon="mdi-cellphone" /> -->
          <CountryPhonePicker
            :value="attendee.phone"
            @input="($event) => { onAttendeeChanges({ key: 'phone', value: {
              phone: $event.phone, number: $event.phone, countryNumber: $event.number, countryCode: $event.code, valid: $event.valid
            } }) }"
          >
          </CountryPhonePicker>
        </v-col>

        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            label="Cédula"
            :value="attendee.document"
            @input="onAttendeeChanges({ key: 'document', value: $event })"
            :rules="[rules.required, rules.onlyNumbers]"
            prepend-icon="mdi-card-account-details-outline" />
        </v-col>

        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            label="Ciudad"
            :value="attendee.city"
            @input="onAttendeeChanges({ key: 'city', value: $event })"
            :rules="[rules.required]"
            prepend-icon="mdi-city-variant-outline" />
        </v-col>

        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            label="Dirección"
            :value="attendee.address"
            @input="onAttendeeChanges({ key: 'address', value: $event })"
            :rules="[rules.required]"
            prepend-icon="mdi-map-marker-outline" />
        </v-col>

        <v-col cols="12" md="6" class="pt-0">
          <v-text-field
            label="Ocupación"
            :value="attendee.profession"
            @input="onAttendeeChanges({ key: 'profession', value: $event })"
            :rules="[rules.required]"
            prepend-icon="mdi-briefcase-account-outline" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import rules from '@/utils/rules'
import CountryPhonePicker from '@/components/CountryPhonePicker/CountryPhonePicker'

export default {
  components: { CountryPhonePicker },
  computed: {
    rules () {
      return rules(this.$t.bind(this))
    }
  },
  methods: {
    deleteAttendee () {
      this.$emit('deleteAttendee')
    },
    onAttendeeChanges (attendeeInfo) {
      this.$emit('onAttendeeChange', attendeeInfo)
    }
  },
  props: {
    attendee: {
      type: Object,
      required: true
    },
    attendeeIndex: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped></style>
