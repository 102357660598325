<template>
  <v-radio-group v-model="localSelectedTicket">
    <v-radio
      style="width: inherit;
             background-color: #444444;
             border-radius: 6px;
             margin-bottom: 10px!important;
             padding: 14px!important;"
      :color="color"
      background-color="white"
      v-for="ticket in tickets"
      :key="ticket._id"
      class="mx-0 mt-0"
      :value="ticket"
    >
      <template v-slot:label>
        <div style="display: flex; width: 100%; justify-content: space-between;">
          <span class="first-letter-capitalized"
                style="font-weight: 500!important; font-size: 18px">
            {{ ticket.title }}
          </span>
          <span style="font-weight: 500!important">
            {{ formatCurrency(ticket.price) }}
          </span>
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>
<script>
import formatCurrency from '@/mixins/formatCurrency'
export default {
  name: 'SelectTicketPrice',
  computed: {
    localSelectedTicket: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mixins: [formatCurrency],
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    value: {
      type: Object,
      default: () => {}
    },
    tickets: {
      type: Array,
      required: true
    }
  },
  methods: {
    getLabel (ticket) {
      if (ticket.price === 0) {
        return `${ticket.title} - FREE`
      }
      const price = this.$options.filters.currency(ticket.price).slice(0, -3)
      const currency = ticket.currency.toUpperCase()

      return `${ticket.title} - ${currency} ${price}`
    }
  }
}
</script>
<style scoped>
.ticket-date {
  color: rgba(0, 0, 0, 0.6);
}

.ticket-date span {
  font-weight: 600;
}

.white--text /deep/ .v-input--selection-controls__input {
  color: aqua;
}

.white--text /deep/ label {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 7px;
}

.white--text /deep/ .v-icon.v-icon {
  color: white;
  font-size: 30px;
}
</style>
