<template>
  <v-container :style="{'background-color': ($vuetify.theme.dark ? '#1e1e1e' : '#ffffff'),
                        'color': ($vuetify.theme.dark ? '#ffffff' : '#1e1e1e')}">
    <!--- Dialogs--->
    <ErrorDialog :message="errorDialog.message" v-model="errorDialog.show" />
    <ApprovedDialog v-model="showApprovedDialog" @approved="goToPurchases"
      purchaseType="event" :isOnlineEvent="selectedEvent.isOnlineEvent"
    />
    <LoginCode
      v-if="showLoginCode"
      v-model="showLoginCode"
      :mainAttendee="mainAttendee"
      @successLogin="confirmPurchase"
      @cancelLogin="isPaying = false"
    />
    <AddNewCreditCardDialog
      v-if="showNewCreditCardDialog"
      v-model="showNewCreditCardDialog"
      :mainAttendee="mainAttendee"
      @creditCardAdded="confirmPurchase"
      @cancelTransaction="isPaying = false"
    />
    <SelectPaymentMethodDialog
      v-if="selectedEvent._id"
      v-model="showSelectPaymentMethodDialog"
      :selectedPaymentMethod="selectedPaymentMethod"
      :currency="selectedEvent.currency"
      :selectedTicket="selectedTicket"
      :numberAttendees="attendees.length"
      @setSelectedPaymentMethod="setSelectedPaymentMethod"
      @confirmSelectedPaymentMethod="confirmPurchase"
      @cancelTransaction="isPaying = false"
    />
    <CreditCardsForPaymentDialog
      v-if="showCreditCardsForPaymentDialog"
      v-model="showCreditCardsForPaymentDialog"
      :totalPayAmount="totalPayAmount"
      @cardSelected="confirmPurchase"
      @openAddNewCreditCardDialog="showNewCreditCardDialog = true"
      @cancelTransaction="isPaying = false"
    />
    <!-- PSE or Cash User Payment Info -->
    <SetPaymentInfoDialog
      v-if="showSetPaymentInfoDialog"
      v-model="showSetPaymentInfoDialog"
      :mainAttendee="mainAttendee"
      @setPaymentInfo="setPaymentInfo"
      @confirmPaymentInfo="confirmPurchase"
      @cancelTransaction="isPaying = false"
    />
    <!--- end Dialogs--->

    <v-row class="pa-0 event-purchase-image-container">
      <v-col class="pa-0 d-flex justify-center">
        <div style="border-radius: 50%; width: 250px; height: 250px"
             :style="image"
             class="event-purchase-container" />
      </v-col>
    </v-row>
    <v-row>
      <v-col @click="goToEventDetail" cols="12" class="purchase-event_title">
        <h1 class="hidden-sm-and-down text-center">
          {{ selectedEvent.title | uppercase() }}
        </h1>
        <h2 class="hidden-md-and-up text-center">
          {{ selectedEvent.title | uppercase() }}
        </h2>
        <p>{{ truncateString(selectedEvent.shortDescription, 30) }}</p>
      </v-col>
      <!--- date --->
      <v-col cols="12"
             class="text-left px-5"
             style="background-color: #222; padding: 20px">
        <div class="purchase-event-date">
          <v-card-text class="py-0 px-0">
            <p class="purchase-event-date_title"
               style="font-weight: 800">{{ $t("starts") }}:</p>
            <p class="purchase-event-date_text text-capitalize"
               style="font-weight: 500">
              {{ selectedEvent.starts | moment("MMMM DD / YYYY / hh a") }}
            </p>
          </v-card-text>
          <v-card-text class="pt-4 px-0">
            <p class="purchase-event-date_title"
               style="font-weight: 800">{{ $t("ends") }}:</p>
            <p class="purchase-event-date_text text-capitalize"
               style="font-weight: 500">
              {{ selectedEvent.ends | moment("MMMM DD / YYYY / hh a") }}
            </p>
          </v-card-text>
        </div>
      </v-col>
      <!--- end date --->
      <v-col cols="12"
             class="pt-0 px-5 purchase-event-access"
             style="background-color: #222">
        <h1 class="text-uppercase" style="font-weight: 800">{{ $t("accessCategories") }}:</h1>
        <v-divider style="margin: 20px 0!important" />
        <div>
          <SelectTicketPrice
            color="#11CDEF"
            class="white--text ma-0"
            v-model="selectedTicket"
            :tickets="selectedEvent.tickets"
            v-if="selectedEvent.tickets"
          />
        </div>
      </v-col>

      <v-col cols="12"
        v-if="(selectedTicket.accessFrom || venueDataStartDate)"
        class="px-5 ticket-counter-container d-flex align-center justify-space-between"
      >
        <Countdown class="ticket-counter-down"
          :date="ticketStartDate((selectedTicket.accessFrom || venueDataStartDate))"
        />
        <v-spacer></v-spacer>
        <div>
          <div class="text-capitalize text-subtitle-2 font-weight-bold">
            {{ (selectedTicket.accessFrom || venueDataStartDate) | moment("MMMM DD YYYY") }}
          </div>
          <div class="text-subtitle-2 font-weight-bold">
            {{ (selectedTicket.accessFrom || venueDataStartDate) | moment("dddd hh:mm a") }}
          </div>
        </div>
      </v-col>
      <template v-if="(selectedTicket.accessFrom || venueDataStartDate) && selectedTicket.type.code === 2">
        <v-col cols="12" class="px-5">
          <v-text-field
            v-model="accessCode"
            :label="$t('ticketAccesCode')"
            :rules="[rules.required, rules.minLength(accessCode, 6)]"
            outlined class="ticket-acess-code"
          ></v-text-field>
        </v-col>
      </template>

      <v-col style="padding-top: 40px!important;">
        <h1 class="text-center">{{ $t("whoIsComing") }}</h1>
      </v-col>
    </v-row>

    <!--- attendees--->
    <template v-if="attendees.length > 0">
      <v-form ref="attendee-form">
        <v-row
          justify="center"
          v-for="(attendee, idx) in attendees"
          :key="idx"
        >
          <div style="margin-bottom: 20px">
            <AddAttendee :attendee="attendee"
                         :attendee-index="idx"
                         @deleteAttendee="deleteAttendee(idx)"
                         @onAttendeeChange="editAttendee($event, idx)" />
          </div>

<!--          <v-col cols="12"
                 md="8"
                 class="d-flex align-center justify-space-between">
            <p class="white&#45;&#45;text">{{ attendee.name }}</p>
            <div class="d-flex justify-end">
              <EditAttendee
                :attendee="attendee"
                :index="idx"
                @edit="handleEditAttendee"
              />
              <p
                class="purchase-event-attendees__delete"
                style="cursor: pointer;"
                @click="deleteAttendee(idx)"
              >
                <v-icon medium color="white">mdi-delete-circle</v-icon>
              </p>
            </div>
          </v-col>-->
        </v-row>
      </v-form>

    </template>

    <!--- Add Attendee --->
    <v-row
      style="margin: 0 auto;max-width: 500px"
      v-if="hasSelectedTicket && canAddAttendees"
    >
      <v-col @click="addAttendee()">
        <v-btn
          color="kbBlue"
          x-large
          style="width: 100%;"
          :outlined="numberOfAttendees > 0"
        >
          {{ $t("addAttendee") }}
          <v-icon
            v-if="$vuetify.breakpoint.smAndUp"
            class="ml-5" right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!--- BOOK TICKETS --->
    <v-row justify="center"
      style="margin: 0 auto;"
    >
      <v-col class="d-flex justify-center">
        <v-btn
          class="purchase-event_btn"
          :loading="isLoadingPurchase"
          x-large
          color="kbGreen"
          dark
          :disabled="isPurchaseBtnDisabled"
          @click="confirmPurchase"
          >{{ $t("continue") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import rules from '@/utils/rules'
import moment from 'moment'
import eventInfo from '@/mixins/eventInfo'
import notifications from '@/plugins/notifications'
import SelectTicketPrice from '@/components/SelectTicketPrice/Index.vue'
import AddAttendee from '@/pages/Venues/VenueEvent/AddAttendee'
import Countdown from '@/components/Countdown/Countdown'
import ErrorDialog from '@/pages/Venues/VenueEvent/Dialogs/ErrorDialog'
import ApprovedDialog from '@/components/Dialogs/ApprovedDialog'
// import AddNewStripeCreditCardDialog from '@/pages/Venues/VenueEvent/Dialogs/AddNewStripeCreditCardDialog'
// import AddNewCreditCardByMercadopagoDialog from '@/pages/Venues/VenueEvent/Dialogs/AddNewCreditCardByMercadopagoDialog'
import AddNewWompiCreditCardDialog from '@/pages/Venues/VenueEvent/Dialogs/AddNewWompiCreditCardDialog'
import LoginCode from '@/pages/Venues/VenueEvent/Dialogs/LoginCode'
import CreditCardsForPaymentDialog from '@/pages/Venues/VenueEvent/Dialogs/CreditCardsForPaymentDialog'
import SelectPaymentMethodDialog from '@/pages/Venues/VenueEvent/Dialogs/SelectPaymentMethodDialog/SelectPaymentMethodDialog'
import SetPaymentInfoDialog from '@/pages/Venues/VenueEvent/Dialogs/SetPaymentInfoDialog'
import windowSize from '@/mixins/windowSize.js'
import formatString from '@/mixins/formatString'
import Image from '@/assets/img/keybe-live-1440x759.jpg'
export default {
  name: 'VenueEventPurchase',
  components: {
    SelectTicketPrice,
    AddAttendee,
    Countdown,
    ErrorDialog,
    ApprovedDialog,
    AddNewCreditCardDialog: AddNewWompiCreditCardDialog,
    CreditCardsForPaymentDialog,
    SelectPaymentMethodDialog,
    SetPaymentInfoDialog,
    LoginCode
  },
  mixins: [eventInfo, windowSize, formatString],
  data: () => ({
    attendees: [],
    accessCode: '',
    isLoadingPurchase: false,
    isLoadingAttendees: false,
    showConfirmPurchase: false,
    showApprovedDialog: false,
    errorDialog: {
      message: '',
      show: false
    },
    showLoginCode: false,
    showNewCreditCardDialog: false,
    showCreditCardsForPaymentDialog: false,
    showSetPaymentInfoDialog: false,
    showSelectPaymentMethodDialog: false,
    selectedPaymentMethod: '',
    paymentUserInfo: {},
    isPaying: false
  }),
  created () {
    // window.addEventListener('onLogout', () => {
    //   this.attendees = []
    //   // this.selectedTicket = {}
    // })

    if (!this.isLoggedIn) {
      this.$store.dispatch('authKeybe/isLoggedIn')
        .then(isLoggedIn => {
          if (isLoggedIn) {
            this.getUserCreditCards()
            this.setUserInfoAsAttendee()
          }
        })
        .catch(err => {
          console.error(err)
        })
    } else
    if (this.userInfo) {
      this.getUserCreditCards()
      this.setUserInfoAsAttendee()
    }
  },
  computed: {
    ...mapState({
      creditCards: state => state.money.creditCards,
      isLoggedIn: state => state.authKeybe.isLoggedIn,
      userInfo: state => state.authKeybe.userInfo
    }),
    numberOfAttendees () {
      return this.attendees && Array.isArray(this.attendees) ? this.attendees.length : 0
    },
    selectedTicket: {
      get () {
        return this.$store.state.storedStates.selectedTicket
      },
      set (value) {
        this.$store.commit('storedStates/SET_SELECTED_TICKET', value)
      }
    },
    selectedCreditCard: {
      get () {
        return this.$store.state.money.selectedCreditCard
      },
      set (value) {
        this.$store.commit('money/SET_SELECTED_CREDIT_CARD', value)
      }
    },
    isPurchaseBtnDisabled () {
      return this.attendees.length === 0
    },
    hasSelectedTicket () {
      return Object.keys(this.selectedTicket).length > 0
    },
    canAddAttendees () {
      const maxPerclient = this.selectedTicket.maxPerClient
      // wheter ticket is with access code cannot add attendees
      const attendeesRegistered = this.attendees.length
      if (attendeesRegistered > 0 && this.selectedTicket.type &&
        this.selectedTicket.type.code === 2) return false
      if (maxPerclient === undefined || maxPerclient === null) return true
      return attendeesRegistered < maxPerclient
    },
    mainAttendee () {
      const attendeesRegistered = this.attendees.length
      if (attendeesRegistered > 0) {
        const mainAttendee = this.attendees[0]
        mainAttendee.email = mainAttendee.email.toLowerCase()
        mainAttendee.email = mainAttendee.email.trim()
        if (mainAttendee.phone && mainAttendee.phone.number) mainAttendee.phone.number = mainAttendee.phone.number.trim()
        return mainAttendee
      }
      return {}
    },
    totalPayAmount () {
      return (this.selectedTicket.price + this.selectedTicket.servicePrice) *
                  this.attendees.length
    },
    image () {
      const imageUrl = this.selectedEvent.coverImage || Image
      return {
        padding: '8px',
        'margin-top': '-0.017em',
        // background: `linear-gradient(175deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), url(${imageUrl})`,
        'background-image': `url(${imageUrl})`,
        'background-size': 'cover',
        'background-position': 'center center'
      }
    },
    rules () {
      // Bind the 'this' variable to allow make call of this.rules
      return rules(this.$t.bind(this))
    }
  },
  methods: {
    addAttendee (attendee = { name: '', email: '', phone: { phone: '' }, document: '' }) {
      // console.log('attendee')
      // console.log(attendee)
      this.attendees.push({ ...attendee, totalTickets: 1 })
    },
    editAttendee (attendeeInfo, index) {
      const { key, value } = attendeeInfo
      this.attendees[index][key] = value
    },
    getUserCreditCards () {
      this.$store.dispatch('money/getUserCards')
    },
    async signUp () {
      notifications.success(this.$t('pleaseLoginBeforeConfirmPayment'))
      window.location.hash = '/kb-profile'
      // window.keybe.uiOpenModal()
      this.$vuetify.goTo(0)
    },
    deleteAttendee (index) {
      this.attendees.splice(index, 1)
    },
    setSelectedPaymentMethod (selectedPaymentMethod) {
      this.selectedPaymentMethod = selectedPaymentMethod
    },
    setPaymentInfo (paymentInfo) {
      this.paymentUserInfo = paymentInfo
    },
    setUserInfoAsAttendee () {
      const { name = '', email = '', phone: phoneNumber = '' } = this.userInfo
      const phone = {
        phone: phoneNumber
      }
      this.addAttendee({ name, email, phone, document: '' })
    },
    ticketStartDate (starts) {
      let ticketStartDate = moment()
      if (starts) {
        ticketStartDate = moment(starts)
        // console.log('starts')
        // console.log(this.selectedEvent.starts)
      }
      // console.log('timeZone')
      // console.log(this.selectedEvent.timeZone)
      let timeZone
      if (this.selectedEvent.timeZone && this.selectedEvent.timeZone.label) {
        timeZone = this.selectedEvent.timeZone.label
        // console.log('ticketStartDate1')
        // console.log(ticketStartDate.format('YYYY-MM-DD hh:mm:ss A Z'))
        ticketStartDate = ticketStartDate.tz(timeZone, true)
      }
      return ticketStartDate
    },
    confirmPurchase () {
      if (!this.$refs['attendee-form'].validate()) return
      if (this.selectedTicket.type.code === 2 &&
        !(this.accessCode && this.accessCode.length >= 6)) {
        return notifications.error(this.$t('mustInsertAccessCode'))
      }

      if (!this.isLoggedIn) {
        this.showLoginCode = true
        // return notifications.error('Por favor ingresa el código de acceso enviado')
        return
      }

      for (let i = 0; i < this.attendees.length; i++) {
        const attendee = this.attendees[i]
        if (!attendee.phone) {
          return notifications.error(this.$t('invalidPhone'))
        }
        if (!attendee.phone.valid || !attendee.phone.number) {
          return notifications.error(this.$t('invalidPhone'))
        }

        let hasOnlyNumbers = this.rules.onlyNumbers(attendee.phone.number)
        // console.log('hasOnlyNumbers')
        // console.log(hasOnlyNumbers)
        if (typeof hasOnlyNumbers === 'string' || !hasOnlyNumbers) {
          notifications.error(this.$t('invalidPhone'))
          return notifications.error(hasOnlyNumbers)
        }
        attendee.fields = [
          {
            name: 'Ciudad',
            value: attendee.city,
            code: 'city'
          },
          {
            name: 'Direccion',
            value: attendee.address,
            code: 'address'
          },
          {
            name: 'Ocupacion',
            value: attendee.profession,
            code: 'profession'
          }
        ]
      }
      if (!this.isPaying) {
        // Reset variables
        this.selectedPaymentMethod = ''
        this.paymentUserInfo = {}
        this.selectedCreditCard = {}
      }
      this.isPaying = true
      // console.log('this.selectedPaymentMethod')
      // console.log(this.selectedPaymentMethod)
      // console.log('this.selectedEvent')
      // console.log(this.selectedEvent)
      // console.log('this.selectedTicket')
      // console.log(this.selectedTicket)
      if (this.selectedTicket.price > 0) {
        // show modal select payment method
        if (!this.selectedPaymentMethod) {
          notifications.info(this.$t('whichPaymentMethodWillBeUsed'))
          this.showSelectPaymentMethodDialog = true
          return
        }
        if (this.selectedPaymentMethod === 'creditCard') {
          // validate user has credit cards:
          if (!this.creditCards.length) {
            notifications.info(this.$t('youHaveNotAddedCreditCardsYet'))
            // TODO: Mostrar el keybe-profile->money->addCreditCard
            // user has no credit cards associated
            this.showNewCreditCardDialog = true
            return
          }

          // console.log('this.selectedCreditCard')
          // console.log(this.selectedCreditCard)
          if (!this.selectedCreditCard.id) {
            notifications.info(this.$t('whichCardWillBeUsed'))
            this.showCreditCardsForPaymentDialog = true
            return
          }
        }

        if (this.selectedPaymentMethod === 'pse') {
          // validate user has filled payment information:
          if (!this.paymentUserInfo.email ||
            !this.paymentUserInfo.docType ||
            !this.paymentUserInfo.docNumber ||
            !this.paymentUserInfo.typePerson ||
            !this.paymentUserInfo.bank) {
            this.$store.dispatch('money/getPseBanks')
            this.$store.dispatch('money/getIdentificationTypes')
            this.$store.dispatch('money/getAcceptanceToken')
            this.showSetPaymentInfoDialog = true
            return
          }
        }
      }
      this.isLoadingPurchase = true
      let purchaseConfirmation = {
        paymentMethod: this.selectedPaymentMethod
      }
      if (this.selectedPaymentMethod === 'creditCard') {
        purchaseConfirmation = {
          ...purchaseConfirmation,
          dues: 1,
          cardId: this.selectedCreditCard.id,
          email: this.mainAttendee.email
        }
      } else if (this.selectedPaymentMethod === 'pse') {
        purchaseConfirmation = {
          ...purchaseConfirmation,
          email: this.paymentUserInfo.email,
          docType: this.paymentUserInfo.docType,
          docNumber: this.paymentUserInfo.docNumber,
          typePerson: this.paymentUserInfo.typePerson,
          urlResponse: `${window.location.protocol}//${window.location.host}/purchases`,
          bank: this.paymentUserInfo.bank
        }
      } else if (this.selectedPaymentMethod === 'cash') {
        purchaseConfirmation = {
          ...purchaseConfirmation

        }
      } else if (this.selectedPaymentMethod === 'mercadopago') {
        purchaseConfirmation = {
          ...purchaseConfirmation

        }
      }
      this.$store.commit('money/SET_PURCHASE_CONFIRMATION', purchaseConfirmation)
      this.$store.dispatch('events/setEventPurchase', {
        ticketId: this.selectedTicket._id,
        attendees: this.attendees,
        code: this.accessCode
      })
        .then((response) => {
          this.isLoadingPurchase = false
          this.isPaying = false
          if (this.selectedTicket.price > 0) {
            // for epayco:
            // if (response.purchase.ticket.type.code === 0) {
            //   this.showApprovedDialog = true
            // }

            if (this.selectedPaymentMethod === 'creditCard') {
              if (response && response.charge) {
                if (response.charge.status.code === 1) {
                  this.showApprovedDialog = true
                } else if (response.charge.status.code === 2) {
                  notifications.error('Lamentamos informar que tu compra fue rechazada')
                } else {
                // notificacion de estado pendiente
                  notifications.success('En contados segundos obtendrás la confirmación de tu compra en tu bandeja de correo electrónico y en tu celular')
                  return this.goToPurchases()
                }
              } else {
                // notificacion de error
                // return notifications.error('Hubo un error procesando tu compra')
                notifications.success('En contados segundos obtendrás la confirmación de tu compra en tu bandeja de correo electrónico y en tu celular')
              }
            } else if (this.selectedPaymentMethod === 'pse') {
              if (response &&
                response.charge &&
                response.charge.payment_method &&
                response.charge.payment_method.extra &&
                response.charge.payment_method.extra.async_payment_url
              ) {
                console.log('response.charge.payment_method.extra.async_payment_url')
                console.log(response.charge.payment_method.extra.async_payment_url)
                // window.open(response.charge.payment_method.extra.async_payment_url, '_blank')
                window.location.replace(response.charge.payment_method.extra.async_payment_url)
              } else {
                // notificacion de error
                return notifications.error('Hubo un error procesando tu compra')
              }
            } else if (this.selectedPaymentMethod === 'cash') {
            } else if (this.selectedPaymentMethod === 'mercadopago') {
            }
          } else {
            this.showApprovedDialog = true
          }
        })
        .catch((error) => {
          console.error(error)
          this.isLoadingPurchase = false
          this.isPaying = false

          this.errorDialog = {
            message: error.message,
            show: true
          }
          if (error.errors && Array.isArray(error.errors) && error.errors.length) {
            this.errorDialog.message = error.errors[0].msg
          }
        })
    },
    goToEventDetail () {
      this.$router.push({
        name: 'VenueEventDetail',
        params: { id: this.$route.params.id }
      })
    },
    goToPurchases () {
      if (this.selectedEvent.isOnlineEvent) {
        if (this.selectedEvent.hasExternalEventUrl) {
          this.goToExternalOnlineEvent()
        } else {
          this.$router.push({
            name: 'VenueEventLiveStream',
            params: { id: this.$route.params.id }
          })
        }
      } else {
        this.$router.push({ name: 'Purchases' })
      }
    },
    goToExternalOnlineEvent () {
      // :href="`${event.externalEventUrl}?code=${attendee.purchaseId}`"
      var eventWindow = window.open(`${this.selectedEvent.externalEventUrl}?code=${this.attendee.purchaseId}`, '_blank')
      // console.log('eventWindow')
      // console.log(this.selectedEvent.externalEventUrl)
      // console.log(eventWindow)
      window.addEventListener('message', (event) => {
        if (event.origin !== 'https://reconexiones.co') { return }
        if (event.data === 'keybeJsLoaded') {
          console.log('Sharing jwt with ' + this.selectedEvent.externalEventUrl)
          eventWindow.postMessage({ jwt: this.userInfo.jwt }, this.selectedEvent.externalEventUrl)
        }
      }, false)
    }
  },
  watch: {
    userInfo (user) {
      if (user) {
        this.getUserCreditCards()
        if (!this.numberOfAttendees) {
          this.setUserInfoAsAttendee()
        }
      }
    },
    selectedEvent (value) {
      // This method is for avoiding clics
      if (!value ||
          !value.tickets ||
          !Array.isArray(value.tickets) ||
          !value.tickets.length) {
        // this.selectedTicket = {}
      } else if (!this.selectedTicket || !Object.keys(this.selectedTicket).length) {
        this.selectedTicket = value.tickets[0]
      } else {
        const foundSelectedTicket = value.tickets.find(ticket => ticket._id === this.selectedTicket._id)
        if (!foundSelectedTicket) {
          this.selectedTicket = value.tickets[0]
        } else {
          this.selectedTicket = foundSelectedTicket
        }
      }
    }
  }
}
</script>
<style
  lang="scss"
  src="@/scss/pages/venues/venueEvent/VenueEventPurchase.scss"
  scoped
/>
